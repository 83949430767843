import Parse from "./parse.service";
import axios from "axios";
const environment = process.env.NODE_ENV || "development"; // Default to 'development' if not set
// import { SERVER_URL } from "./stripe.service";

const SERVER_URL = environment === "development"
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD;

//method to delete the image from aws s3 and in event
export const deleteObjectFromS3 = async (filename, eventId) => {
  try {
    const response = await Parse.Cloud.run("deleteCoverPhotoObject", {
      key: filename,
    });
    console.log("deleteObjectFromS3 Response", response);
    if (response?.success) {
      const Event = Parse.Object.extend("Event");
      const eventQuery = new Parse.Query(Event);
      const event = await eventQuery?.get(eventId);
      if (event) {
        event.set("cover_photo", "");
        await event.save();
      } else {
        console.error("Event not found ", event);
      }
    }
    return response;
  } catch (error) {
    console.error("Error in deleteObjectFromS3: ", error);
  }
};

//method to check the image already exist in aws
export const checkObject = async (filename) => {
  if (filename) {
    const response = await Parse.Cloud.run("checkCoverPhotoObject", {
      key: filename,
    });
    return response;
  } else {
    return;
  }
};

//method to add the image in aws storage
export const addImagetoS3 = async (file) => {
  const formData = new FormData();
  formData.append("file", file);

  let user = Parse.User.current();
  let token = user.get("sessionToken");

  try {
    const response = await axios.post(`${SERVER_URL}/uploadImage`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "parse-session-token": token,
      },
    });
    console.log("addImagetoS3 File uploaded successfully:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};