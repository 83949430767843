export const addMinutes = (date, minutes) => {
  date?.setMinutes(date?.getMinutes() + minutes);
  return date;
};

//method to convert amount to Norway currency
export const currencyFormatter = (amount) => {
  var formattedCurrency = new Intl.NumberFormat("nb-NB", {
    maximumFractionDigits: 0,
    style: "currency",
    currency: "NOK",
    currencyDisplay: "narrowSymbol",
  }).format(amount);

  return formattedCurrency;
};

//method to return the aws object url key
export const getKeyFromS3Url = (url) => {
  const segments = url?.split("/");
  return segments[segments?.length - 1]; // Return the last segment, which is the key
};

//add 2 hours ahead of current time
export const getdateTime = () => {
  const currentDate = new Date();
  currentDate?.setHours(currentDate?.getHours() + 2);
  return currentDate;
};

//reduce the 2hours to event Date Time
export const getGateOpenTime = (eventDate)=>{
  const newDate = new Date(eventDate);
  newDate?.setHours(newDate?.getHours() - 2);
  return newDate
}

// Function to get the end the of currrent date
export const getEndOfDay = (date) => {
  const endOfDay = new Date(date);
  endOfDay?.setHours(23, 59, 59, 999); // Set to the last moment of the day
  return endOfDay;
};

//Fucntion to get start date of current date
export const getStartOfDay = () => {
  const date = new Date();
  date?.setHours(0, 0, 0, 0, 0);
  return date;
};

// Sorting events based on event date in ascending order
export const sortingData = (events, field = "createdAt", order = "desc") => {
  if(!events?.length>0){
    return [];
  }
  const sortedEvents = events?.sort((a, b) => {
    const dateA = new Date(a.get("date"));
    const dateB = new Date(b.get("date"));
    return dateA - dateB;
    // const dateA = new Date(a?.get(field));
    // const dateB = new Date(b?.get(field));
    
    // if (order === "asc") {
    //   return dateA - dateB; // Ascending order
    // } else {
    //   return dateB - dateA; // Descending order
    // }
  });
  return sortedEvents;
};

 // Sorting events by date in descending order
export const sortEventsByDate = (events) => {
  return [...events].sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );
}

