import "App.css";
import logo from "assets/images/logo.svg"
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook, faSquareInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="bg-footer mt-auto">
      <div className="footer py-5">
        <img src={logo} alt="logo" />
        <br />
        <p className="py-3">
          <a href="https://facebook.com/Mintixofficial/" className="px-2">
            {/* <img src={facebook} alt="facobook-logo" width="3%" /> */}
            <FontAwesomeIcon style={{color:"#0d2c48"}} className="fs-1" icon={faSquareFacebook} />
          </a>
          <a href="https://www.instagram.com/mintixofficial?igsh=cWo2OWZ2ems1ZnRw">
          <FontAwesomeIcon style={{color:"#0d2c48"}} className="fs-1" icon={faSquareInstagram} />
            {/* <img src={instagram} alt="instagram-logo" width="3%" /> */}
          </a>
        </p>
        <a href="/terms" className="text-reset small">
          {t("terms")}
        </a>
        <br />
        <br />
        <p className="small">{t("footer_description")}</p>
      </div>
    </div>
  );
};
export default Footer;