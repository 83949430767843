import Parse from "parse";
const environment = process.env.NODE_ENV || "development"; // Default to 'development' if not set
export const SERVER_URL =
  environment === "development"
    ? process.env.REACT_APP_SERVER_URL_DEV
    : process.env.REACT_APP_SERVER_URL_PROD;

export function getPaymentStatus(sessionId) {
  return fetch(`${SERVER_URL}/session-status?session_id=${sessionId}`).then(
    (res) => res.json()
  );
}

// export function createCheckout(ticketAmount, userEmail, bookingId) {
//   let user = Parse.User.current();
//   let token = user.get("sessionToken");
//   return fetch(`${SERVER_URL}/create-checkout-session`, {
//     method: "POST",
//     headers: {
//       "Content-Type": "application/json",
//       "parse-session-token":token
//     },
//     body: JSON.stringify({
//       amount: ticketAmount,
//       email: userEmail,
//       bookingId: bookingId,
//     }),
//   }).then((res) => res.json());
// }

export const createCheckout = async (ticketAmount, userEmail, bookingId) => {
  try {
    // Get the current user and their session token
    const user = Parse.User.current();
    if (!user) throw new Error("User not logged in");
    
    const token = user?.get("sessionToken");

    // Make the POST request to create the checkout session
    const response = await fetch(`${SERVER_URL}/create-checkout-session`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "parse-session-token": token,
      },
      body: JSON.stringify({
        amount: ticketAmount,
        email: userEmail,
        bookingId: bookingId,
      }),
    });

    // Check if the response was successful
    if (!response.ok) {
      throw new Error(`Failed to create checkout session: ${response.statusText}`); 
    }

    // Parse and return the response JSON
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error creating checkout session:", error);
    throw error;
  }
};

//method to make the refund amount
export const refundPayment = async ( paymentIntentId, boookingAmount = 0, bookingId ) => {
  
  const amount = parseInt(boookingAmount) * 100; // Convert to smallest currency unit (e.g., cents).

  try {
    // Initiating the refund process
    const res = await Parse.Cloud.run("createCharge", { paymentIntent: paymentIntentId, amount });

    // Fetch the booking object and its associated event
    const Booking = Parse.Object.extend("Booking");
    const bookingQuery = new Parse.Query(Booking);
    const bookingResponse = await bookingQuery?.get(bookingId);

    const numberOfPeople = bookingResponse?.get("numberOfPeople")
    const eventId = bookingResponse?.get("eventId").id;

    if (!numberOfPeople || !eventId) {
      throw new Error("Booking details are incomplete.");
    }

    // Proceed if the charge was successful
    if (res?.status === "succeeded") {
      const Event = Parse.Object.extend("Event");
      const eventQuery = new Parse.Query(Event);
      const eventResponse = await eventQuery.get(eventId);

      const eventBookingsCount = eventResponse?.get("bookings_count");
      const availableTickets = eventBookingsCount?.max_tickets;
      const refundTickets = eventBookingsCount?.refund_tickets;


      if (availableTickets === undefined) {
        throw new Error("Event ticket count is not available.");
      }
      
      //couting max tickets and refund tickets
      const updatedBookingCount = { ...eventBookingsCount, max_tickets: availableTickets + numberOfPeople , refund_tickets: refundTickets + numberOfPeople};

      // Update refund status for the booking
      bookingResponse.set("refundStatus", { status: true, payment_intent: paymentIntentId });

      // Update event's bookings count and max tickets
      eventResponse.set("bookings_count",updatedBookingCount);
      eventResponse.set("max_tickets",availableTickets + numberOfPeople);
      
      const res = await eventResponse.save();
      const BookingResponse = await bookingResponse.save();
      return {
        code: 200,
        booking_response: BookingResponse,
        refund_status: res,
      };
    }
  } catch (error) {
    console.error("refundPayment error ", error);
    return { code: error.code || 500, message: error.message };
  }
};

//method to check the refund status
export const checkRefundStatus = async (refundId) => {
  try {
    const refundStatus = await Parse.Cloud.run("checkRefundStatus", {
      refundId,
    });
    return refundStatus;
  } catch (error) {
    console.error("checkRefundStatus error ", error);
  }
};