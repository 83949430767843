import React from "react";
import { Scanner } from "@yudiel/react-qr-scanner";

//Ticket Verification Form Modal
const CheckTicket = ({ ticketDetails, toggleCamera, handleScan, handleError, handleTicketDetails, handleTicket, handleCheckIn, handleCloseModel, closeButtonRef }) => {

//camera priview styles
const previewStyle = { height: "100%", width: "100%" };

return (
    <div className="modal fade" id="checkTicket"  aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 fw-bolder" id="exampleModalLabel"> Event CheckIn </h1>
            <button type="button" className="btn-close" data-bs-dismiss="modal" ref={closeButtonRef} onClick={handleCloseModel} aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form>
              <label htmlFor="" className="fw-bold mb-2"> Ticket Id </label>
              <div className="position-relative">
                <input type="text" name="ticketId" className="form-control" placeholder="Enter Ticket Id"
                  value={ticketDetails?.ticketId || ""}
                  onChange={handleTicketDetails}
                />
                <i className="bi bi-camera position-absolute top-0 end-0 fs-4 px-2 cursor" onClick={toggleCamera}></i>
              </div>
              {ticketDetails?.error && <p className={`${ticketDetails?.isVerify ? "text-success" : "text-danger"} m-0 mt-2`}>{ticketDetails?.error}</p>}
              <br />
            </form>
            <div>
              {  //Qr Scanner Component will open when condition is true
                (ticketDetails?.showCamera) &&
                 <Scanner onScan={handleScan} onError={handleError} style={previewStyle}/>
              }
            </div>
            {ticketDetails?.isVerify && ticketDetails?.isCheckIn && <p >It will close in next {ticketDetails?.counter} seconds</p>}
          </div>
          <div className="modal-footer">
            <button disabled={ticketDetails?.isVerify} className="btn btn-secondary  fw-bold" onClick={handleTicket}> Verify </button>
            <button className="btn btn-secondary  my-2 fw-bold"
              disabled={!ticketDetails?.isVerify || ticketDetails?.isCheckIn} onClick={handleCheckIn}>
              CheckIn
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckTicket;