import { getKeyFromS3Url } from "utils";
import { fetchUser } from "./auth.service";
import { auth, awsAPI, couponAPI } from "services";
import Parse from "./parse.service";

const Event = Parse.Object.extend("Event");
const Booking = Parse.Object.extend("Booking");

//fetching all events and automatically isLive can set to false if the event is outdated
export const getEvents = async () => {
  try {
    const query = new Parse.Query(Event);
    query.include("organizerId");
    query.addAscending("date");
    const res = await query.find();
    const currentDate = new Date();

    for (let event of res) {
      const eventDate = event.get("date");
      const isLive = event.get("isLive");
      if (eventDate < currentDate && isLive === 1) {
        event.set("isLive", 2);
        // Save the updated event individually
        await event.save();
      }
    }
    return res;
  } catch (error) {
    console.error("Error fetching or updating events:", error);
    throw error;
  }
};

// //fetch the only live Events
// export const getLiveEvents = async () => {
//   try {
//     const query = new Parse.Query(Event);
//     query.equalTo("isLive", 1);
//     const res = await query.find();
//     return res;
//   } catch (error) {
//     console.error("Error fetching or updating events:", error);
//     throw error;
//   }
// };

//get the all organizer events base on organizer Id
export const organizerEvents = async () => {
  try {
    const currentUser = auth.fetchUser();
    const query = new Parse.Query(Event);
    query.equalTo("organizerId", currentUser);
    query.addDescending("createdAt");
    const res = await query.find();
    return res;
  } catch (error) {
    console.error("organizerEvents error ", error);
  }
};

//get the Organizerevents based on Organizer Id
export const getEventByUserId = async () => {
  const res = fetchUser();
  const userId = res.id;
  const user = Parse.User.createWithoutData(userId);
  const query = new Parse.Query(Event);
  query.equalTo("organizerId", user);
  try {
    const data = await query.find();
    return data;
  } catch (error) {
    console.error("user error ", error);
  }
};

//add event method
export async function addEvent(event) {
  const currentUser = Parse.User.current();
  const myEvent = new Event();

  const eventObj = {
    title: event?.title,
    description: event?.description,
    date: event?.date,
    max_tickets: event?.max_tickets,
    ticket_types: event?.ticket_types,
    address: event?.address,
    cover_photo: event?.cover_photo || "",
    gate_open_time: event?.gate_open_time,
    gate_close_time: event?.gate_close_time,
    sale_start_date: event?.sale_start_date,
    isLive: 0,
  };

  const booking_details = {
    totalTickets: eventObj?.max_tickets,
    max_tickets: eventObj?.max_tickets,
    sold_tickets: 0,
    refund_tickets: 0,
    // total_tickets_amount: 0,
    // refund_tickets_amount: 0,
    // ticket_types_count: {},
  };

  const coupon_details = {};
  try {
    myEvent.set("bookings_count", booking_details);
    myEvent.set("coupons_count", coupon_details);
    myEvent.set("organizerId", currentUser);
    myEvent.set("send_email", false);
    const newEvent = await myEvent.save(eventObj);
    return newEvent;
  } catch (error) {
    console.error("newEvent Error is ", error);
  }
}

export function getEventDetail(id) {
  const query = new Parse.Query(Event);
  query.limit(1);
  query.equalTo("objectId", id);
  query.include("organizerId");
  return query
    .find()
    .then((results) => {
      if (results.length != 0) {
        return results[0];
      } else {
        return null;
      }
    })
    .catch((error) => {
      console.error("getting single event by id error ", error);
    });
}

//event update method
export async function updateEvent(event, id) {
  try {
    const query = new Parse.Query(Event);
    query.limit(1);
    query.equalTo("objectId", id);
    const eventdetails = await query.get(id);

    let updatedBookingCounts = {
      ...eventdetails?.get("bookings_count"),
      totalTickets: event?.max_tickets,
      max_tickets: event?.max_tickets,
    };

    eventdetails.set("title", event?.title);
    // eventdetails.set("ticket_types", event?.ticket_types);
    eventdetails.set("ticket_types", event?.ticket_types);
    eventdetails.set("description", event?.description);
    eventdetails.set("cover_photo", event?.cover_photo);
    eventdetails.set("max_tickets", event?.max_tickets);
    eventdetails.set("address", event?.address);
    eventdetails.set("date", event?.date);
    eventdetails.set("bookings_count", updatedBookingCounts);
    eventdetails.set("gate_open_time", event?.gate_open_time);
    eventdetails.set("gate_close_time", event?.gate_close_time);
    eventdetails.set("sale_start_date", event?.sale_start_date);
    const res = await eventdetails.save();
    return res;
  } catch (error) {
    console.error("Ticket Upadate Error is ", error);
  }
}

//make event live method
export async function postEventLive(id) {
  try {
    const query = new Parse.Query(Event);
    query?.equalTo("objectId", id);
    const eventDetails = await query?.get(id);
    eventDetails.set("isLive", 1);
    const eventData = await eventDetails?.save();
    //method to making all coupons live when event go live
    const couponData = await couponAPI.getCouponbyEventId(id);
    const couponPromises = couponData.map(async (item) => {
      if (item?.get("isActive")) {
        return true;
      } else {
        item.set("isActive", true);
        return item.save();
      }
    });

    //all coupons are saving
    await Promise.all(couponPromises);
    return eventData;
  } catch (error) {
    console.error("Event Golive Error ", error);
  }
}

//method to make event live(1) or draft (0) based on EventId
export const postEventLiveOrDraft = async (eventId, eventStatusValue) => {
  try {
    const query = new Parse.Query(Event);
    const event = await query?.get(eventId);
    event.set("isLive", eventStatusValue);
    const eventData = await event?.save();
    return eventData;
  } catch (error) {
    console.error("Event Golive Error ", error);
    throw new Error(error);
  }
};

//delete event method
export async function deleteEventbyId(id) {
  const query = new Parse.Query(Event);
  query.equalTo("objectId", id);
  try {
    const eventdetais = await query.get(id);
    const imgUrl = eventdetais?.get("cover_photo");
    const objectKey = await getKeyFromS3Url(imgUrl);
    const checkImgexist = await awsAPI.checkObject(objectKey);
    if (checkImgexist?.exists) {
      await awsAPI.deleteObjectFromS3(objectKey);
    }
    const res = await eventdetais.destroy();
    return res;
  } catch (error) {
    console.error("Deleting Event Error ", error);
  }
}

//update event Tickets count and types of Tickets count and coupons count
export async function eventTicketUpdate(bookingData) {
  if (!bookingData) return;
  const { eventId, totalTickets, totalAmount, ticketSoldOut, couponCode } =
    bookingData;

  const maxTicketTotal = totalTickets;

  try {
    // Fetch the event object by eventId
    const query = new Parse.Query(Event);
    query.equalTo("objectId", eventId);
    const eventObject = await query.first();

    if (!eventObject) return;
    const eventBookingCount = eventObject?.get("bookings_count");
    const eventCouponCount = eventObject?.get("coupons_count");

    const updatedBookingsCount = {
      ...eventBookingCount,
      max_tickets: maxTicketTotal,
      sold_tickets: (eventBookingCount?.sold_tickets || 0) + ticketSoldOut,
    };

    eventObject.set("bookings_count", updatedBookingsCount); // Update booking count
    eventObject.set("max_tickets", maxTicketTotal); //updating tickets count

    //updating coupons count
    if (couponCode) {
      eventCouponCount[couponCode] = (eventCouponCount[couponCode] || 0) + 1;
    }

    //updating coupon quantity
    await couponAPI.updateCouponQuantity(couponCode, eventId);

    const res = await eventObject.save();
    return res.toJSON();
  } catch (error) {
    console.error("eventTicketUpdate error ", error);
    throw error;
  }
}

export const getEventGrossAmount = async(eventId)=>{
  try {
   // Create a pointer to the event object
    const eventPointer = new Parse.Object("Event");
    eventPointer.id = eventId; 

    const bookingQuery = new Parse.Query(Booking);
    bookingQuery.equalTo("eventId",eventPointer);
    const bookingsArray = await bookingQuery.find();

    // Calculate gross amount by reducing the valid bookings
    const grossAmount = bookingsArray?.reduce((accumualtor,item)=>{
      const validBooking = item?.get("status") === "complete";
      if(validBooking) return accumualtor+(item?.get("amount") ?? 0)
        return accumualtor;
    },0)
    return grossAmount;
  } catch (error) {
    console.error("getEventGrossAmount Error ",error)
    throw error;
  }
}

//method to update the Event refund amount and refund tickets count
export const updateRefundAmount = async (eventId, bookingId, refundAmount) => {
  try {
    const eventQuery = new Parse.Query(Event);
    const bookingQuery = new Parse.Query(Booking);

    const eventDetails = await eventQuery?.get(eventId);
    const bookingDetails = await bookingQuery?.get(bookingId);

    const booking_count_details = eventDetails?.get("bookings_count");
    const availableTickets = eventDetails?.get("max_tickets");
    const numberOfPeople = bookingDetails?.get("numberOfPeople");

    // const ticketRefundAmount = (booking_count_details?.refund_tickets_amount || 0) + refundAmount;
    const numberOfTicketsRefund = (booking_count_details?.refund_tickets) + numberOfPeople;
    const availableEventTickets = availableTickets + numberOfPeople;
    const updatedBooking = { ...booking_count_details, refund_tickets: numberOfTicketsRefund, max_tickets:availableEventTickets
    };

    eventDetails.set("max_tickets",availableEventTickets);
    eventDetails.set("bookings_count", updatedBooking);
    const response = await eventDetails.save();
    return response;
  } catch (error) {
    console.error("updateRefundAmount error ", error);
  }
};

//method to get fetch the event organization information for tono mail
export const fetchOragnizerInformation = async (eventId) => {
  const res = await getEventDetail(eventId);
  const singleEvent = res;
  let event_information = {
    event_name: singleEvent?.get("title"),
    event_address: singleEvent?.get("address"),
    event_start_date: singleEvent?.get("date"),
    total_tickets_count: singleEvent?.get("bookings_count")?.sold_tickets,
    audient_count: singleEvent?.get("bookings_count")?.sold_tickets,
    // gross_revenue: singleEvent?.get("bookings_count")?.total_tickets_amount || 0,
  };

  const userdetails = singleEvent?.get("organizerId");

  let organizer_information = {
    organizer_name: userdetails?.get("username"),
    organizer_phone: userdetails?.get("profileId")?.get("phone"),
    organizer_country: userdetails?.get("profileId")?.get("country"),
    organizer_email: userdetails?.get("email"),
    organizer_address: userdetails?.get("profileId")?.get("address"),
  };

  let event_organizer_detiails = {
    ...organizer_information,
    ...event_information,
  };

  return event_organizer_detiails;
};

//method to update the tono pdf send status
export const sendEmailStatus = async (eventId) => {
  try {
    const eventQuery = new Parse.Query(Event);
    const eventResponse = await eventQuery.get(eventId);

    eventResponse.set("send_email", true);
    const res = await eventResponse.save();
    return res;
  } catch (error) {
    console.error("sendEmailStatus event error ", error);
    return error;
  }
};