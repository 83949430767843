import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { Header, Footer } from "./components/shared";
import Spinner from "components/shared/Spinner";
import "./App.css";
import "react-datepicker/dist/react-datepicker.css";
import userStore from "store/userStore";
import { auth } from "services";

// Lazy load views
const Home = lazy(() => import("./views/Home"));
const EventDetail = lazy(() => import("./views/EventDetail"));
const Checkout = lazy(() => import("./views/Checkout"));
const About = lazy(() => import("./views/pages/About"));
const Terms = lazy(() => import("./views/pages/Terms"));
const Login = lazy(() => import("./views/Auth/Login"));
const Signup = lazy(() => import("./views/Auth/Signup"));
const Profile = lazy(() => import("./views/Profile"));
const Tickets = lazy(() => import("./views/Tickets"));
const Return = lazy(() => import("./views/Return"));
const Admin = lazy(() => import("./views/Admin"));
const NotFound = lazy(() => import("views/pages/NotFound"));
const Stepper = lazy(() => import("views/Admin/Stepper/Index"));
const Dashboard = lazy(() => import("views/Dashboard"));
const PasswordReset = lazy(() => import("./views/Auth/PasswordReset"));
const TonoForm = lazy(() => import("./views/Tono/index"));

function App() {
  const { intializeState } = userStore((state) => state);

  //when first time page loads it will intialize the globalStore
  useEffect(() => {
    initializeData();
  }, []);

  //intialize the global state
  const initializeData = async () => {
    try {
      await intializeState();
    } catch (error) {
      console.error("Error initializing data: ", error);
    }
  };

  return (
    <BrowserRouter>
      <div className="container-fluid">
        <Header />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/about" element={<About />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/event/:id" element={<EventDetail />} />
            <Route path="/checkout" element={<ProtectedRoute><Checkout /></ProtectedRoute>} />
            <Route path="/return" element={<ProtectedRoute><Return /></ProtectedRoute>} />
            <Route path="/tickets" element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
            <Route path="/events/addEvent" element={<ProtectedRoute><Stepper /></ProtectedRoute>} />
            <Route path="/eventEdit/:id" element={<ProtectedRoute><Stepper /></ProtectedRoute>} />
            <Route path="/events" element={<ProtectedRoute><Admin /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} /> 
            <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
            <Route path="/tono/:id" element={<ProtectedRoute><TonoForm /></ProtectedRoute>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
        <Footer />
      </div>
    </BrowserRouter>
  );

}

const ProtectedRoute = ({ children }) => {
  const navigate = useNavigate();
  const { user } = userStore((state) => state);
  if(!user){
    const _user = auth.fetchUser();
    if(!_user){
      return navigate("/");
    }else{
      return <>{children}</>;
    }
  }
  // if (!user) return <Navigate to="/" />;
  return <>{children}</>;
};

export default App;