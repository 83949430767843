import { fetchUser } from "./auth.service";
import Parse from "./parse.service";
const Bank = Parse.Object.extend("Bank");

//method to add the organizer bank details
export const addBankDetails = async (bankdata) => {
    // delete bankdata.confirmAccountNumber;
    const currentUser = Parse.User.current();
    try {
      const bank = new Bank();
      bank.set("organizerId", currentUser);
      const res = await bank.save(bankdata);
      return res;
    } catch (error) {
      console.error("addBankDetails Error", error);
      throw error;
    }
  };
  
  //method to fetch the user bank details based on userId
  export const getBankDetails = async () => {
    try {
      let currentUser = fetchUser();
      const BankQuery = new Parse.Query(Bank);
      BankQuery.equalTo("organizerId", currentUser);
      const res = await BankQuery?.find();
      return res[0];
    } catch (error) {
      console.error("getBankDetails Error ", error);
      throw error;
    }
  };
  
  //method to update the bank acount details
  export const updateBankDetails = async (BankDetails, id) => {
    try {
      let currentUser = fetchUser();
      const BankQuery = new Parse.Query(Bank);
      BankQuery.equalTo("organizerId", currentUser);
  
      const bankdetails = await BankQuery.get(id);
      bankdetails.set("accountName", BankDetails?.accountName);
      bankdetails.set("accountNumber", Number(BankDetails?.accountNumber ?? 0));
      bankdetails.set(
        "confirmAccountNumber",
        Number(BankDetails?.confirmAccountNumber ?? 0)
      );
      bankdetails.set("accountType", BankDetails?.accountType);
      bankdetails.set("location", BankDetails?.location);
      bankdetails.set("code", BankDetails?.code);
  
      const res = await bankdetails.save();
      return res;
    } catch (error) {
      console.error("updateBankDetails Error ", error);
      throw error;
    }
  };
  