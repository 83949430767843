import React, { useState, useRef } from "react";
import { bookingAPI } from "services";
import CheckTicket from "components/Modals/CheckTicket";
import TicketCheckIn from "utils/validation/TicketCheckIn";

const Scanner = () => {
  const [ticketDetails, setTicketDetails] = useState({
    ticketId: "",
    isVerify: false,
    isCheckIn: false,
    counter: 3,
    showCamera: false,
    error:null,
  });
  const { ValidateField, TicketCheckInForm } = TicketCheckIn();
  const closeButtonRef = useRef(null);

  //handle update the state
  const updateState = (obj)=> setTicketDetails((prevState)=>({...prevState, obj}))

  //handle Scanner Data Errors
  const handleError = (err) => {
    console.error("Scanner error:", err);
    updateState({error:err})
  };
  
  //method to turnOff the Camera
  const handleTurnOFFCamera = () => setTicketDetails((prev) => ({ ...prev, showCamera: false }));

  //handle successfull scan
  const handleScan = (data) => {
    if (data) {
      const qrData = data?.[0]?.rawValue;
      setTicketDetails((prev) => ({ ...prev, ticketId: qrData, showCamera: false}));
    }
  };

  //method to toggle the camera ON or OFF
  const toggleCamera = () => {
    setTicketDetails((prev) => ({ ...prev, showCamera: !prev.showCamera }));
  };

  //method to check the ticket is valid or not
  const handleTicket = async () => {
    const { err } = TicketCheckInForm(ticketDetails);
    if (err) {
      updateState({error:err?.ticketId});
      return;
    }
    try {
      const res = await bookingAPI.verifyTicket(ticketDetails?.ticketId);
      if (res) {
        setTicketDetails((prev) => ({...prev, isVerify: true, isCheckIn: res?.get("checkedIn")}));
        if (res?.get("checkedIn")) {
          updateState({error:"Valid Ticket.. Already Checked-In"})

          // if ticket is already checked then counter decrement time interval will call
          const myInterval = setInterval(() => {
            setTicketDetails((prev) => ({...prev, counter: prev.counter - 1}));
          }, 1000);

          setTicketDetails((prev) => ({ ...prev, intervalId: myInterval }));

          //after 3 seconds it wil clear the counter decrement time interval
          setTimeout(() => {
            if (closeButtonRef.current) {
              closeButtonRef.current.click();
            }
            handleCloseModel();
            clearInterval(myInterval);
          }, 3000);
        } else {
          updateState({error:"Valid Ticket.. Kindly proceed to Check-In"})
        }
      }
    } catch (error) {
      console.error("handleTicket error ", error);
      updateState({error:"Please Enter valid Ticket ID"})
    }
  };

  //method to allow the ticket checkIn
  const handleCheckIn = async () => {
    try {
      const bookingResponse = await bookingAPI.verifyCheckIn(
        ticketDetails?.ticketId
      );
      if (bookingResponse?.get("checkedIn")) {
        setTicketDetails((prev) => ({ ...prev, isCheckIn: true }));
        updateState({error:"Successfully CheckedIn"})

        //it ticket checkin is done successfully then counter decrement time interval wil call
        const myInterval = setInterval(() => {
          setTicketDetails((prev) => ({ ...prev, counter: prev.counter - 1 }));
        }, 1000);
        setTicketDetails((prev) => ({ ...prev, intervalId: myInterval }));

        //it will call after 3seconds and it will clear the counter time decrement interval
        setTimeout(() => {
          if (closeButtonRef.current) {
            closeButtonRef.current.click();
          }
          handleCloseModel();
          clearInterval(myInterval);
        }, 3000);
      }
    } catch (error) {
      console.error("handleCheckIn error ", error);
    }
  };

  //method to update the ticketDetails state
  const handleTicketDetails = (e) => {
    const { name, value } = e.target;
    const err = ValidateField(name, value);
    updateState({error:err})
    setTicketDetails((prev) => ({ ...prev, [name]: value }));
  };

  //method to close the modal
  const handleCloseModel = () => {
    setTicketDetails((prev) => ({
      ...prev,
      ticketId: "",
      intervalId: "",
      isVerify: false,
      isCheckIn: false,
      counter: 3,
      showCamera: false,
    }));
    //it wil cleat the interval if it has been already set
    if (ticketDetails?.inervalId) {
      clearInterval(ticketDetails?.intervalId);
    }
    // setError("");
    updateState({error:""})
  };
  // CheckTicket modal Props
  const checkTicketProps = {
    ticketDetails,
    closeButtonRef,
    toggleCamera,
    handleTurnOFFCamera,
    handleError,
    handleScan,
    handleTicketDetails,
    handleTicket,
    handleCheckIn,
    handleCloseModel,
  };

  return (
    <div>
      <button
        type="button"
        className="btn fw-bold mt-2 border"
        data-bs-toggle="modal"
        data-bs-target="#checkTicket"
      >
        Check Ticket
      </button>
      {/* Ticket Check Modal */}
      <CheckTicket {...checkTicketProps} />
    </div>
  );
};

export default Scanner;
