import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { useTranslation } from "react-i18next";
import logo from "assets/images/logo.svg"
import Parse from "parse";
import userStore from "store/userStore";
import Scanner from "./Scanner";

const Header = () => {
  const [countryLanguage, setCountryLanguage] = useState();
  const { user, role } = userStore((state) => state);
  const { t, i18n } = useTranslation(["common", "home"]);
  const navigate = useNavigate();

  //intially it will set the localization language as norway
  useEffect(() => {
    let currentLanguage = i18n.language;
    setCountryLanguage(currentLanguage);
  }, [i18n.language]);

  //handle to show the language
  const showLanguage = () => {
    if (countryLanguage === "en") {
      return "EN";
    } else if (countryLanguage === "nw") {
      return "NO";
    }
  };

  //method for logout user
  const Logout = async () => {
    await Parse.User.logOut();
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  //handle change browser language
  const handleLanguage = (language) => {
    i18n.changeLanguage(language);
    setCountryLanguage(language);
    localStorage.setItem("language", JSON.stringify(language));
  };

  //handle user not logged In
  const nonLoggedIn = () => {
    return (
      <Link to="/login" className="text-reset text-decoration-none fs-5">
        <div className="mt-1">
          <FontAwesomeIcon icon={faUser} className="mx-0" />
          <p className="d-inline fs-4 px-2">{t("login")}</p>
        </div>
      </Link>
    );
  };
  
  //handle user already logged In
  const loggedIn = () => {
    return (
      <div className="d-md-flex">
        <div className="dropdown">
          <a className="nav-link dropdown-toggle fs-5 fw-bold" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown">
            {user?.get("username")}
          </a>
          <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink" >
            {role === "user" && (
              <li className="cursor">
                <p className="dropdown-item m-0" onClick={() => navigate("/tickets")} >
                  {t("dropdown.myTickets")}
                </p>
              </li>
            )}
            <li>
              {role !== "user" && (
                <p className="dropdown-item m-0" onClick={() => navigate("/events")} role="button" >
                  {t("dropdown.event")}
                </p>
              )}
            </li>
            <li> <hr className="dropdown-divider" /> </li>
            <li>
              <p className="dropdown-item m-0" onClick={() => navigate("/profile")} role="button">
                {t("dropdown.profile")}
              </p>
            </li>
            <li>
              <a className="dropdown-item" href=" " role="button" onClick={Logout} >
                {t("dropdown.logout")}
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderLoginLinks = () => user ? loggedIn() : nonLoggedIn();

  return (
    <nav className="navbar navbar-expand-lg mb-md-3">
      <div className="container">
        <Link className="navbar-brand" to={role === "admin" || role === "organizer" ? "/dashboard" : "/"}>
          <img src={logo} alt="logo" />
        </Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav w-100">
            <li className="nav-item me-auto">
              <Link className="nav-link active fs-5" to="/about">
                {t("header_title")}
              </Link>
            </li>
            {role !== "user" &&
              (role === "organizer" || role === "admin") && (
                <li><Scanner/></li>
              )}
            <li className="nav-item">
              <div className="dropdown">
                <button className="btn dropdown-toggle fw-bold fs-5 py-1 mt-1 px-md-2 px-0" type="button" data-bs-toggle="dropdown">
                  {showLanguage()}
                </button>
                <ul className="dropdown-menu">
                  <li className="cursor">
                    <a className="dropdown-item" onClick={() => handleLanguage("en")}> EN </a>
                  </li>
                  <li>
                    <a className="dropdown-item cursor" onClick={() => handleLanguage("nw")} > NO </a>
                  </li>
                </ul>
              </div>
            </li>
            <li className="nav-item dropdown">{renderLoginLinks()}</li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default Header;