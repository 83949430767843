const Parse = require("./parse.service");

// Function to retrieve the current user
function fetchUser() {
  return Parse.User.current();
}

//update the profile data based on profile Id
const updateProfile = async (userDetails) => {
  try {
    const Profile = Parse.Object.extend("Profile");
    const query = new Parse.Query(Profile);
    const currentUser = fetchUser();
    const profileId = currentUser?.get("profileId")?.id;
    let currentUserEmail = currentUser?.get("email");

    if (userDetails?.email) {
      if (currentUserEmail !== userDetails?.email) {
        currentUser.set("email", userDetails?.email);
        await currentUser.save();
      }
    }

    const profile = await query.get(profileId);
    profile.set("address", userDetails?.address);
    profile.set("phone", userDetails?.phone);
    profile.set("country", userDetails?.country);
    profile.set("dateOfBirth", userDetails?.dateOfBirth);
    profile.set("firstname", userDetails?.firstname);
    profile.set("lastname", userDetails?.lastname);
    profile.set("gender", userDetails?.gender);
    await profile.save();
  } catch (error) {
    console.error("updating Profile Error ", error);
    return error;
  }
};

//get user profile data based on current user Profile Id
const getProfileData = async () => {
  try {
    let currentUserId = fetchUser();
    let profileId = currentUserId?.get("profileId")?.id;

    const Profile = Parse.Object.extend("Profile");
    const query = new Parse.Query(Profile);

    const res = await query.get(profileId);
    return res.attributes;
  } catch (error) {
    throw error;
  }
};

//new user signup
const signupUser = async (userdetails) => {
  let { username, firstname, email, lastname, password } = userdetails;
  const user = new Parse.User();
  user.set("username", username);
  user.set("password", password);
  user.set("email", email);

  try {
    let newUser = await user.signUp();
    if (newUser) {
      const Profile = Parse.Object.extend("Profile");
      const profile = new Profile();

      // //authorised user only see the user profile details
      // const profileACL = new Parse.ACL(newUser);
      // profile.setACL(profileACL);

      // Allow public access to the Profile object
      const profileACL = new Parse.ACL();
      profileACL.setPublicReadAccess(true);
      profileACL.setPublicWriteAccess(true);
      profile.setACL(profileACL);
      user.setACL(profileACL);

      profile.set("firstname", firstname);
      profile.set("lastname", lastname);

      const newProfile = await profile.save();
      user.set("profileId", newProfile);
      await user.save();
    }
    await Parse.User.logIn(username, password);
    const sessionToken = Parse.User.current().getSessionToken();
    //assigning new user default role is user
    const query = new Parse.Query(Parse.Role);
    //finding static user role
    query.equalTo("name", "user");
    const role = await query.first({ sessionToken });

    if (role) {
      role.getUsers().add(user);
      await role.save(null, { sessionToken });
    } else {
      console.error("role not found ", role);
    }
    // await Parse.User.logOut();
    return user;
  } catch (error) {
    console.error("SignUp Error ", error);
    throw error.message;
  }
};

//method to delete user account and user profile data
const deleteAccount = async () => {
  const currentUser = Parse.User.current();
  if (currentUser) {
    try {
      //deleting user account
      const res = await currentUser.destroy();

      //deleting user profile data
      let profileId = currentUser?.get("profileId")?.id;
      const Profile = Parse.Object.extend("Profile");
      const profileQuery = new Parse.Query(Profile);

      const profile = await profileQuery.get(profileId);
      await profile.destroy();
      return res;
    } catch (error) {
      console.error("delete Account error ", error);
    }
  }
};

//Change Passoword
const changePassword = async (newpassword) => {
  const currentUser = Parse.User.current();
  if (currentUser && newpassword) {
    try {
      currentUser.setPassword(newpassword);
      const res = await currentUser.save();
      await Parse.User.logOut();
      return res;
    } catch (error) {
      console.error("Error updating the new Password ", error);
      throw error;
    }
  }
};

// Function to log in a user
async function login(username, password) {
  return Parse.User.logIn(username, password);
}

// Function to log out the current user
function logout() {
  return Parse.User.logOut();
}

// Function to send a password reset email
function requestPasswordReset(email) {
  return Parse.User.requestPasswordReset(email);
}

// Function to send an email verification
function sendVerificationEmail(email) {
  const status = new Promise((resolve, reject) => {
    const user = fetchUser();
    const randomEmail = "someone@anymail.com";
    user.set("email", randomEmail);
    user.save()
      .then((result) => {
        user.set("email", email);
        user
          .save()
          .then((result) => {
            resolve("Verification email sent successfully!");
          })
          .catch((err) => {
            reject("Something went wrong!");
          });
      })
      .catch((err) => {
        reject("Something went wrong!");
      });
  });
  return status;
}

//method to verify the user
const checkUserAuthenticated = async (password) => {
  try {
    const user = await fetchUser();
    let username = user?.get("username");
    const isAuthenticatd = await login(username, password);
    return isAuthenticatd;
  } catch (error) {
    throw { code: 101, message: error?.message };
  }
};

module.exports = {
  fetchUser,
  signupUser,
  login,
  logout,
  requestPasswordReset,
  sendVerificationEmail,
  changePassword,
  updateProfile,
  getProfileData,
  deleteAccount,
  checkUserAuthenticated,
};