import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { api, auth, bookingAPI, couponAPI, eventAPI } from "services";

const userDetails = (set, get) => ({
  user: null,
  role: null,
  // eventDetails:{},
  events: [],
  bookings: [],
  coupons: [],
  bank: [],

  // //method to ge the user role
  // getUserRole: async () => {
  //   const { role } = get();
  //   if (role) return role;
  //   try {
  //     const userRole = await api.getCurrentUserRoles();
  //     set((prev) => ({ ...prev, role: userRole }));
  //     return userRole;
  //   } catch (error) {
  //     console.error("Error fetching user role", error);
  //   }
  // },

  // //method to get the oganizer events based on role
  // getEvents: async () => {
  //   const { role, Events } = get();
  //   if (Events?.length > 0) return;
  //   try {
  //     let fetchedEvents = [];
  //     if (role === "organizer") {
  //       fetchedEvents = await eventAPI.organizerEvents(); // Organizer can access only his events
  //     } else {
  //       fetchedEvents = await eventAPI.getEvents(); // Admin can access all events
  //     }
  //     set((prev) => ({ ...prev, events: fetchedEvents }));
  //   } catch (error) {
  //     console.error("Error fetching events", error);
  //   }
  // },

  // //method to get the organizer event bookings based on role
  // getBookings: async () => {
  //   const { role, bookings } = get();
  //   if (bookings?.length > 0) return;
  //   try {
  //     let fetchedBookings = [];
  //     if (role === "organizer") {
  //       fetchedBookings = await bookingAPI.getOrganizerAllBookings();
  //     }else{
  //       fetchedBookings = await bookingAPI.getBookings();
  //     }
  //     set((prev) => ({ ...prev, bookings: fetchedBookings }));
  //   } catch (error) {
  //     console.error("Error fetching bookings", error);
  //   }
  // },

  // //method to get the coupons based on role
  // getCoupons: async () => {
  //   const { role } = get();
  //   try {
  //     let fetchedCoupons = [];
  //     if (role === "admin") {
  //       fetchedCoupons = await couponAPI.getCoupon(); //all coupons
  //     }

  //     if (role === "organizer") {
  //       fetchedCoupons = await couponAPI.getOrganizerCoupons(); //organizer coupons
  //     }
  //     set((prev) => ({ ...prev, coupons: fetchedCoupons }));
  //   } catch (error) {
  //     console.error("Error fetching coupons", error);
  //   }
  // },

  //method to update the global state
  write: (obj) => {
    if (typeof obj === "function") {
      const result = obj(get());
      set(result);
      return;
    }
    set((state) => ({ ...state, ...obj }));
  },

  //intially it will fetch the current user and role and all events data
  intializeState: async () => {
    try {
      const [user, role] = await Promise.all([
        auth.fetchUser(),
        api.getCurrentUserRoles(),
        // eventAPI.getEvents()
      ])

      // REVIEW : why using prev here ?
      set((prev)=>({...prev, user,role}))
    } catch (error) {
      console.error("Failed to fetch the Intial Data :", error);
    }
  },
});

const userStore = create(devtools(userDetails, { name: "user" }));

export default userStore;