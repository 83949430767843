import { fetchUser } from "./auth.service";
import Parse from "./parse.service";

const Coupon = Parse.Object.extend("Coupon");
const Event = Parse.Object.extend("Event");

export const addCoupons = async (coupons, eventId, statusValue) => {
  const currentuser = Parse.User.current();
  try {
    // Create an array of promises for saving each coupon
    const savePromises = coupons.map(async (data) => {
      const couponData = {
        code: data?.code,
        type: parseInt(data?.type),
        amount: parseInt(data?.amount),
        isActive: statusValue,
        quantity: parseInt(data?.quantity),
      };

      const coupon = new Coupon();
      // Create a Parse.Object instance for Event with just the object ID
      const eventPointer = Parse.Object.extend("Event");
      const event = new eventPointer();
      event.id = eventId;
      coupon.set("eventId", event);
      coupon.set("organizerId", currentuser);
      coupon.set("used_quantity", 0);

      try {
        // Save the coupon
        const newCoupon = await coupon.save(couponData);
        return newCoupon; // Return the saved coupon
      } catch (error) {
        console.error("Error saving coupon:", error);
        return null; // Return null for failed save
      }
    });

    // Wait for all save operations to complete
    const results = await Promise.all(savePromises);

    // Filter out any null results (failed saves)
    const successfulCoupons = results.filter((result) => result !== null);

    return {
      success: true,
      coupons: successfulCoupons,
    };
  } catch (error) {
    console.error("Error adding coupons:", error);
    return {
      success: false,
      message: "Error adding coupons",
    };
  }
};

export const checkCoupons = async (couponCode, eventId) => {
  try {
    const existingCoupon = await getCouponByCode(couponCode, eventId);
    if (existingCoupon) {
      return { code: 200, message: "Coupon is available" };
    } else {
      return null;
    }
  } catch (error) {
    console.error("checkCoupons error:", error);
    throw new Error({ code: 101, message: error.message });
  }
};

//get all coupons method and return default coupons
export const getCoupon = async () => {
  try {
    const query = new Parse.Query(Coupon);
    const res = await query.find();
    return res;
  } catch (error) {
    console.error("getCoupon error ", error);
  }
};

export const getOrganizerCoupons = async () => {
  try {
    const user = fetchUser();
    const query = new Parse.Query(Coupon);
    query.equalTo("organizerId", user);
    const res = await query.find();
    return res;
  } catch (error) {
    console.error("getOrganizerCoupons error ", error);
    throw error;
  }
};

//method to fetch the coupon details based on eventId
export const getCouponbyEventId = async (id) => {
  try {
    const couponQuery = new Parse.Query(Coupon);
    const newEvent = new Event();
    newEvent.id = id;
    couponQuery.equalTo("eventId", newEvent);
    const res = await couponQuery.find();
    return res;
  } catch (error) {
    console.error("getCouponbyEventId error ", error);
  }
};

//get coupon by couponId
export const getCouponbyId = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const res = await query.find();
    return res[0];
  } catch (error) {
    console.error("getCouponbyId Error ", error);
    return error;
  }
};

//update coupon by coupon Id
export const updateCoupon = async (id, newCoupon, eventId) => {
  const getStatus = (status) => {
    if (status === true) {
      return true;
    } else {
      return false;
    }
  };
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("code", newCoupon?.code);
    couponDetails.set("amount", parseInt(newCoupon?.amount));
    couponDetails.set("quantity", parseInt(newCoupon?.quantity));
    couponDetails.set("type", parseInt(newCoupon?.type));
    couponDetails.set("isActive", getStatus(newCoupon?.isActive));
    const res = await couponDetails.save();
    return res;
  } catch (error) {
    console.error("Updating Coupon Data Error ", error);
    return error;
  }
};

//handle the coupon to make active or inactive
export const toggleSwitch = async (id, status) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponDetails = await query.get(id);

    couponDetails.set("isActive", status.get("isActive"));
    await couponDetails.save();
  } catch (error) {
    console.error("toggleSwitch update Error", error);
    return error;
  }
};

//delete coupon by coupon ID
export const deleteCoupon = async (id) => {
  try {
    const query = new Parse.Query(Coupon);
    query.equalTo("objectId", id);
    const couponsDetails = await query.get(id);
    const res = await couponsDetails.destroy();
    return res;
  } catch (error) {
    console.error("Delete Coupon Error ", error);
    throw error;
  }
};

export const updateCouponQuantity = async (couponCode, eventId) => {
  try {
    const query = new Parse.Query(Coupon);
    const event = new Event();
    event.id = eventId;
    query.equalTo("code", couponCode);
    query.equalTo("eventId", event);

    const res = await query.find();
    const couponQuery = res[0];

    if (couponQuery) {
      const quantity = couponQuery?.get("quantity");
      const isActive = couponQuery?.get("isActive");
      const usedQuantity = couponQuery?.get("used_quantity") ?? 0;

      if (quantity > 0 && isActive) {
        let updatedQuantity = quantity - 1;
        // let usedQuantity = (couponQuery?.get("used_quantity") ?? 0) + 1;
        let newUsedQuantity = usedQuantity + 1;

        couponQuery?.set("quantity", updatedQuantity);
        couponQuery?.set("used_quantity", newUsedQuantity);
        if (updatedQuantity === 0) {
          couponQuery?.set("isActive", false);
        }
        const res = await couponQuery?.save();
        return res;
      } else {
        console.log("quantity is nagative  or zero not active ", quantity);
      }
    }
  } catch (error) {
    console.error("updateCouponQuantity error ", error);
    throw error;
  }
};

export function getCouponByCode(_code, eventId) {
  const query = new Parse.Query(Coupon);
  const event = new Event();
  event.id = eventId;
  query.equalTo("code", _code);
  query.equalTo("eventId", event);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length !== 0) {
        let coupon = results[0];
        return coupon;
        // let isActive = coupon?.get("isActive");
        // if (isActive) {
        //   return results[0];
        // } else {
        //   return null;
        // }
      }
      return null;
    })
    .catch((err) => {
      console.error("getCouponByCode Error ", err);
    });
}

export function applyCoupon(_code) {
  const query = new Parse.Query(Coupon);
  query.equalTo("code", _code);
  query.limit(1);
  return query
    .find()
    .then((results) => {
      if (results.length != 0) return results[0];
      return null;
    })
    .catch((err) => {
      console.error("applyCoupon error ", err);
    });
}