import Parse from "./parse.service";

export function pdfGenerate() {
  return Parse.Cloud.run("generateTicket")
    .then((res) => res)
    .catch((err) => err);
}

//get the current user role name
export const getCurrentUserRoles = async () => {
  const Role = Parse.Object.extend("_Role");
  const currentUser = Parse.User.current();
  if (!currentUser) return null;
  const query = new Parse.Query(Role);
  query.equalTo("users", currentUser);
  const userRoles = await query.find();
  const res = userRoles.map((role) => role.get("name"));
  return res[0];
};

//get the user information based on userId
export const getUserDetails = async (userId) => {
  try {
    const query = new Parse.Query(Parse.User);
    query.equalTo("objectId", userId);
    const user = await query.first();
    if (!user) return null;
    return user;
  } catch (error) {
    throw error;
  }
};

//method to check the email is exist in data base or not for creatinf new user
export const getUserEmail = async (email) => {
  const existingUser = await Parse.Cloud.run("getUserByEmail", { email });
  return existingUser;
};