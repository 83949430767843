import { auth } from "services";
import Parse from "./parse.service";

const Event = Parse.Object.extend("Event");
const Booking = Parse.Object.extend("Booking");

export function createBooking(eventId, amount, coupon, totalPeople) {
  const booking = new Booking();
  booking.set("userId", auth.fetchUser());
  booking.set("status", "open");
  booking.set("amount", amount);
  booking.set("coupon", coupon);
  booking.set("checkedIn", false);
  booking.set("numberOfPeople", totalPeople);
  booking.set("refundStatus", { status: false, payment_intent: "" });

  const event = new Event();
  event.id = eventId;
  booking.set("eventId", event);

  return booking.save().then(
    (booking) => {
      return booking;
    },
    (error) => {
      return error?.message;
    }
  );
}

//method to update the booking payment status and paymentIntentId
export const updatePaymentIntent = async (bookingId, paymentIntentId) => {
  try {
    const booking = new Parse.Query(Booking);
    const bookingQuery = await booking.get(bookingId);
    const obj = { status: false, payment_intent: paymentIntentId };
    bookingQuery.set("refundStatus", obj);
    await bookingQuery.save();
  } catch (error) {
    console.error("updatePaymentIntent error ", error);
  }
};

export async function updateBookingPayment(bookingId) {
  const booking = await getBookingById(bookingId);
  booking.set("status", "complete");
  return booking.save();
}

export async function updateBookingTicketLink(bookingId, link) {
  const booking = await getBookingById(bookingId);
  booking.set("ticket_url", link);
  return booking.save();
}

//get all organizers bookings (admin can see all bookings)
export async function getBookings() {
  try {
    const query = new Parse.Query(Booking);
    query.include("eventId");
    query.include("userId");
    query.addDescending("createdAt");
    const results = await query.find();
    return results.length > 0 ? results : null;
  } catch (error) {
    console.error("Error fetching bookings: ", error);
    throw error;
  }
}

//get user bookings (user can see his bookings)
export async function getUserBookings() {
  try {
    const query = new Parse.Query(Booking);
    query.equalTo("userId", auth.fetchUser());
    query.include("eventId");
    query.include("userId");
    query.addDescending("createdAt");
    const results = await query.find();
    return results.length > 0 ? results : null;
  } catch (error) {
    console.error("Error fetching bookings: ", error);
    throw error;
  }
}

//get single organizer events bookings
export const getOrganizerAllBookings = async () => {
  try {
    const currentUser =  Parse.User.current();
    //creating Event POinter
    const event = new Parse.Query(Event);
    event.equalTo("organizerId", currentUser);

    const booking = new Parse.Query(Booking);
    booking.matchesQuery("eventId", event);
    booking.include("userId");

    const result = await booking.find();
    return result;
  } catch (error) {
    console.error("getOrganizerAllBookings error ", error);
  }
};

//method to get the bookings details based on role only completed booking
export const getBookingsByRole = async (role) => {
  try {
    const query = new Parse.Query(Booking);
    query.equalTo("status", "complete");
    query.include("userId");

    // Admin can see all organisers events bookings
    if (role === "admin") {
      query.include("eventId");
    }

    //organiser can see his bookings
    if (role === "organizer") {
      const currentUser =  Parse.User.current();
      const eventQuery = new Parse.Query(Event);
      eventQuery.equalTo("organizerId", currentUser);
      query.matchesQuery("eventId", eventQuery);
    }
    
    const bookings = await query.find();
    return bookings;

    // let totalBookings = 0;
    // let bookingsAmount = 0;
    // let refundAmount = 0;
    // let totalRefunds = 0;

    // // Analyze bookings for refunds
    // bookings?.forEach((booking) => {
    //   const amount = booking?.get("amount");
    //   const refundStatus = booking?.get("refundStatus")?.status;
    //   const totalBookingPeople = booking?.get("numberOfPeople") ?? 0;

    //   if (refundStatus) {
    //     refundAmount += amount;
    //     totalRefunds += totalBookingPeople;
    //   } else {
    //     bookingsAmount += amount;
    //     totalBookings += totalBookingPeople;
    //   }
    // });

    // return {
    //   totalBookings,
    //   bookingsAmount,
    //   refundAmount,
    //   totalRefunds,
    // };
  } catch (error) {
    console.error("Error calculating total amount: ", error);
    throw error;
  }
};

export const getAllBookingsByEventId = async (eventId) => {
  try {
    //creating event pointer
    const eventPointer = new Parse.Object("Event");
    eventPointer.id = eventId;

    const bookingQuery = new Parse.Query("Booking");
    bookingQuery.equalTo("eventId", eventPointer);

    const bookingsArray = await bookingQuery.find();

    let totalTickets = 0;
    let totalAmount = 0;

    bookingsArray?.forEach((booking) => {
      if (
        booking?.get("status") === "complete" &&
        !booking?.get("refundStatus")?.status
      ) {
        totalTickets += booking?.get("numberOfPeople") ?? 0;
        totalAmount += booking?.get("amount") ?? 0;
      }
    });
    return { totalTickets, totalAmount };
  } catch (error) {
    console.error("getAllBookingsByEventId error ", error);
  }
};

export function getBookingById(id) {
  const query = new Parse.Query(Booking);
  query.equalTo("objectId", id);
  query.limit(1);
  query.include("eventId");
  return query
    .find()
    .then((bookings) => {
      if (bookings.length != 0) {
        return bookings[0];
      }
      return null;
    })
    .catch((err) => err);
}

export const getAllBookings = async () => {
  try {
    const booking = new Parse.Query(Booking);
    booking.include("userId");
    booking.include("eventId");
    const result = await booking.find();
    return result;
  } catch (error) {
    console.error("getAllBookings error ", error);
  }
};

//method to verify the Ticket

export const verifyTicket = async (ticketId) => {
  try {
    const bookingQuery = new Parse.Query(Booking);
    bookingQuery.equalTo("status", "complete");
    const bookingResponse = await bookingQuery.get(ticketId);

    if (bookingResponse) {
      return bookingResponse;
    }
    return null;
  } catch (error) {
    console.error("verifyTicket error  ", error);
    throw new Error(error);
  }
};

//method to allow the checkIn
export const verifyCheckIn = async (ticketId) => {
  try {
    const bookingQuery = new Parse.Query(Booking);
    const bookingResponse = await bookingQuery?.get(ticketId);

    if (bookingResponse) {
      bookingResponse.set("checkedIn", true);
      await bookingResponse.save();
      return bookingResponse;
    }
    return null;
  } catch (error) {
    console.error("verifyTicket error  ", error);
    throw new Error(error);
  }
};

export async function createTicket(bookingId) {
  const bookingResponse = await getBookingById(bookingId);
  const event = bookingResponse?.get("eventId");
  const address = event?.get("address");
  const venue = address?.split(",")[0];
  const splitAddress = address?.split(" ");
  const city = splitAddress[splitAddress.length - 1];
  const user = auth.fetchUser();

  const params = {
    ticketId: bookingResponse.id,
    title: event?.get("title"),
    date: event?.get("date"),
    time: "19:00",
    city: city,
    venue: venue,
    address: address,
    price: bookingResponse?.get("amount"),
    email: user?.get("email"),
  };

  return await Parse.Cloud.run("createTicket", params);
}